import React from 'react'

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { detectAnyAdblocker } from 'just-detect-adblock'

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1.5
    },
    tablet: {
        breakpoint: { max: 1024, min: 401 },
        items: 1.5
    },
    mobile: {
        breakpoint: { max: 400, min: 0 },
        items: 1.4
    }
};




export default function en() {
    window.fbq('track', 'Lead', {currency: "EUR", value: 0.00});
    const openInNewTabApp = async (text) => {
        let url = window.generateUrl
        let detected = await detectAnyAdblocker()
        console.log(detected)
        console.log(window.browser)
    
        let body = {
    
          incomingURL: window.location.href,
          outcomingURL: url,
          browser: window.browser,
          adblock: detected,
          button: text
        }
        fetch('https://genesis-billing-server.herokuapp.com/log', {
          method: 'POST',
          body: JSON.stringify(body),
          headers: {
            'Content-type': 'application/json'
          }
        })
        window.fbq('track', 'Lead', { currency: "EUR", value: 0.00 });
        if(url === null){
            const newWindow = window.open(window.location.href ,'_blank', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null
          }else{
            const newWindow = window.open(url ,'_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
          }
      }
    return (
        <div className="App">
            <img src="/img/mainEn2.png" style={{ width: '100%', position: 'absolute', zIndex: -1, marginTop: 56 }} />
            <header className="header2">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="/img/logo.png" style={{ width: '36px', marginLeft: 16 }} />
                </div>
                <div>
                    <button className="button" style={{background: '#F4357E', color: 'white',width:144,height:36}} onClick={() => openInNewTabApp('Start now')} >Start now</button>
                </div>
            </header>
            <div style={{ width: '80%', marginTop: '56%', display: 'flex', justifyContent: 'flex-end' }}>
                <img src="/img/app2.png" style={{ width: '45%', height: '100%', marginRight: '15%' }} onClick={() => openInNewTabApp('AppStore')} />

            </div>

            <button className="button3" style={{ marginTop: '428%', width: '80%', height: '48px' }} onClick={() => openInNewTabApp('Start now')} >Try stylish fonts</button>
            <video width="100%" autoPlay muted loop style={{marginTop:'430%'}}>
                <source src="./img/video.mp4" type="video/mp4" />
            </video>
            <div className="bottom__Container" style={{ width: '100%', background: 'black', marginTop: '5%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <button className="button3" style={{ width: '80%', height: '48px', marginTop: '10%' }} onClick={() => openInNewTabApp('Start now')} >Start now</button>
                <p style={{ width: '90%' }} className="text">User Feedbacks</p>
                <div className='multicarousel' style={{ maxWidth: window.screen.width, marginTop: 30, width: '95%', background: 'black', float: 'right' }}>
                    <Carousel responsive={responsive} arrows={false} showDots slidesToSlide={1}>
                        <img className='slider__img' src="/img/feedbacks/feedback11.png" />
                        <img className='slider__img' src="/img/feedbacks/feedback12.png" />
                        <img className='slider__img' src="/img/feedbacks/feedback13.png" />
                        <img className='slider__img' src="/img/feedbacks/feedback14.png" />
                        <img className='slider__img' src="/img/feedbacks/feedback15.png" />
                    </Carousel>
                </div>
                <img src="/img/app2.png" style={{ width: '40%', height: 'auto', margin: '50px 0px 50px 0px' }} onClick={() => openInNewTabApp('AppStore')} />
            </div>



        </div>
    );
}

import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { detectAnyAdblocker } from 'just-detect-adblock'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1.5
  },
  tablet: {
    breakpoint: { max: 1024, min: 401 },
    items: 1.7
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1.5
  }
};

export default function en() {
  const openInNewTabApp = async (text) => {
    let url = window.generateUrl
    let detected = await detectAnyAdblocker()
    console.log(detected)
    console.log(window.browser)

    let body = {

      incomingURL: window.location.href,
      outcomingURL: url,
      browser: window.browser,
      adblock: detected,
      button: text
    }
    fetch('https://genesis-billing-server.herokuapp.com/log', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json'
      }
    })
    window.fbq('track', 'Lead', { currency: "EUR", value: 0.00 });
    if(url === null){
      const newWindow = window.open(window.location.href ,'_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
    }else{
      const newWindow = window.open(url ,'_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }
  
  return (
    <div className="App">
      <img src="/img/Widgets3.png" style={{ width: '100%', position: 'absolute', zIndex: -1, marginTop: 0 }} />
      <header className="header1">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/img/logo.png" style={{ width: '34px', margin: 15 }} />
          <div className="vl">
          </div>
        </div>
        <div>
          <button className="button button8" style={{fontFamily: 'Open Sans'}}onClick={() => openInNewTabApp('Start now')}>Download now</button>
        </div>

      </header>
      <img src="/img/app1.png" style={{ width: '60%', width: 205,height:64, marginTop: '224%' }} onClick={() => openInNewTabApp('AppStore')}/>
     
    </div>
  );
}
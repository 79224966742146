import logo from './logo.svg';
import './App.css';
import { Switch, Route } from 'react-router-dom'
import en1 from './components/en1'
import En2 from './components/en2'
import fr from './components/fr'
import sp from './components/sp'
import pt from './components/pt'
import Short1 from './components/short1'
import Short2 from './components/short2'
import Short3 from './components/short3'
import Short4 from './components/short4'
import Short5 from './components/short5'
import Short6 from './components/short6'
import Short7 from './components/short7'
import Short8 from './components/short8'
import Short9 from './components/short9'
import Short10 from './components/short10'
import Short11 from './components/short11'
import Short12 from './components/short12'
import Short13 from './components/short13'
import Short20 from './components/Short20'
import Short21 from './components/Short21'
import Short22 from './components/Short22'
import Short23 from './components/Short23'
import Short24 from './components/Short24'
import Short25 from './components/Short25'
import Short26 from './components/Short26'
import Short27 from './components/Short27'
import Short28 from './components/Short28'
import Short29 from './components/Short29'
import Multilanding from './components/Multilanding'
import { useEffect } from 'react';
import AutoRedirect from './components/AutoRedirect';



function App() {
  useEffect(()=>{
    console.log(window.cookie)
    let cookie = document.cookie
    console.log(cookie)
    console.log(process.env)
  },[])
  return (
    <Switch>
      <Route path='/old' component={en1} exact/>
      <Route path='/new' component={En2} exact/>
      <Route path='/es' component={sp}/>
      <Route path='/fr' component={fr}/>
      <Route path='/pt' component={pt}/>
      <Route path='/widgets_short' component={Short1}/>
      <Route path='/widgets_short2' component={Short3}/>
      <Route path='/widgets_short3' component={Short4}/>
      <Route path='/icons_short' component={Short2}/>
      <Route path='/icons_short2' component={Short5}/>
      <Route path='/short1' component={Short6}/>
      <Route path='/short2' component={Short7}/>
      <Route path='/short3' component={Short8}/>
      <Route path='/short4' component={Short9}/>
      <Route path='/short5' component={Short10}/>
      <Route path='/short6' component={Short11}/>
      <Route path='/short7' component={Short12}/>
      <Route path='/short8' component={Short13}/>
      <Route path='/short20' component={Short20}/>
      <Route path='/short21' component={Short21}/>
      <Route path='/short22' component={Short22}/>
      <Route path='/short23' component={Short23}/>
      <Route path='/short24' component={Short24}/>
      <Route path='/short25' component={Short25}/>
      <Route path='/short26' component={Short26}/>
      <Route path='/short27' component={Short27}/>
      <Route path='/short28' component={Short28}/>
      <Route path='/short29' component={Short29}/>
      <Route path="/multilanding" component={Multilanding}/>
      <Route path="/autoredirect" component={AutoRedirect}/>

      <Route component={en1}/>

    </Switch>
  )

}

export default App;


import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { detectAnyAdblocker } from 'just-detect-adblock'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1.5
  },
  tablet: {
    breakpoint: { max: 1024, min: 401 },
    items: 1.7
  },
  mobile: {
    breakpoint: { max: 400, min: 0 },
    items: 1.5
  }
};

export default function en() {
  const openInNewTabApp = async (text) => {
    let url = window.generateUrl
    let detected = await detectAnyAdblocker()
    console.log(detected)
    console.log(url)

    let body = {

      incomingURL: window.location.href,
      outcomingURL: url,
      browser: window.browser,
      adblock: detected,
      button : text
    }
    fetch('https://genesis-billing-server.herokuapp.com/log', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json'
      }
    })
    window.fbq('track', 'Lead', { currency: "EUR", value: 0.00 });
    if(url === null){
      const newWindow = window.open(window.location.href ,'_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
    }else{
      const newWindow = window.open(url ,'_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
    
  }
  return (
    <div className="App">
      <img src="/img/mainEn1.png" style={{ width: '100%', position: 'absolute', zIndex: -1, marginTop: 60 }} />
      <header className="header1">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/img/logo.png" style={{ width: '34px', margin: 15 }} />
          <div className="vl">
          </div>
        </div>
        <div>
          <button className="button" style={{ fontFamily: 'Open Sans' }} onClick={() => openInNewTabApp('Start now')}>Start now</button>
        </div>

      </header>
      <img src="/img/app1.png" style={{ width: '60%', maxWidth: 145, marginTop: '56%' }} />
      <button className="button" style={{ width: 150, marginLeft: 20, marginTop: -45, opacity: 0 }} onClick={() => openInNewTabApp('AppStore')}>Start now</button>
      <button className="button button2" style={{ width: '70%', marginTop: '70%', maxWidth: 250, marginTop: '995%', height: 48, opacity: 1, marginLeft: 24 }} onClick={() => openInNewTabApp('Start now')}>Start now</button>
      <p style={{ width: '90%' }} className="text">User feedbacks</p>
      <div className='multicarousel' style={{ maxWidth: window.screen.width }}>
        <Carousel responsive={responsive} arrows={false} showDots slidesToSlide={1}>
          <img className='slider__img' src="/img/feedbacks/feedback11.png" />
          <img className='slider__img' src="/img/feedbacks/feedback12.png" />
          <img className='slider__img' src="/img/feedbacks/feedback13.png" />
          <img className='slider__img' src="/img/feedbacks/feedback14.png" />
          <img className='slider__img' src="/img/feedbacks/feedback15.png" />
        </Carousel>
      </div>
      <img src="/img/app1.png" style={{ width: '60%', maxWidth: 145, marginTop: '10%' }} />
      <button style={{ marginTop: -45, width: '40%', height: 40, opacity: 0 }} onClick={() => openInNewTabApp('AppStore')}>click</button>
    </div>
  );
}
import React, { useEffect } from 'react'
import { Redirect } from 'react-router'

export default function AutoRedirect(props){
   useEffect(()=>{
        let url = window.generateUrl
        if(url === null){
            const newWindow = window.open(window.location.href ,'_top', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null
          }else{
            const newWindow = window.open(url ,'_top', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
          }
   },[])
    return <></>
}
import React from 'react'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { detectAnyAdblocker } from 'just-detect-adblock'

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1.5
    },
    tablet: {
      breakpoint: { max: 1024, min: 401 },
      items: 1.7
    },
    mobile: {
      breakpoint: { max: 400, min: 0 },
      items: 1.5
    }
  };

export default function en() {
  const openInNewTabApp = async (text) => {
    let url = window.generateUrl
    let detected = await detectAnyAdblocker()
    console.log(detected)
    console.log(window.browser)

    let body = {

      incomingURL: window.location.href,
      outcomingURL: url,
      browser: window.browser,
      adblock: detected,
      button: text
    }
    fetch('https://genesis-billing-server.herokuapp.com/log', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json'
      }
    })
    window.fbq('track', 'Lead', { currency: "EUR", value: 0.00 });
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
    return (
        <div className="App">
            <img src="/img/mainSp.png" style={{ width: '100%', position: 'absolute', zIndex: -1, marginTop: 60 }} />
            <header>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src="/img/logo.png" style={{ width: '34px', margin: 15 }} />
                    <div className="vl">
                    </div>
                </div>
                <div>
                    <button className="button" onClick={() => openInNewTabApp('Start now')}>Iniciar Ahora</button>
                </div>

            </header>
            <img src="/img/app.png" style={{ width: '60%', maxWidth: 145, marginTop: '56%' }} onClick={() => openInNewTabApp('AppStore')} />
            <button className="button button2" style={{ width: '70%', marginTop: '70%', maxWidth: 250, marginTop: '995%', height: 48, opacity: 1, marginLeft: 24 }} onClick={() => openInNewTabApp('Start now')}>Iniciar Ahora</button>
            <p style={{ width: '90%' }} className="text">Iniciar Ahora</p>
            <div className='multicarousel' style={{ maxWidth: window.screen.width }}>
                <Carousel responsive={responsive} arrows={false} showDots slidesToSlide={1}>
                    <img className='slider__img' src="/img/feedbacks/feedback6.png" />
                    <img className='slider__img' src="/img/feedbacks/feedback7.png" />
                    <img className='slider__img' src="/img/feedbacks/feedback8.png" />
                    <img className='slider__img' src="/img/feedbacks/feedback9.png" />
                    <img className='slider__img' src="/img/feedbacks/feedback10.png" />
                </Carousel>
            </div>
            <img src="/img/app.png" style={{ width: '60%', maxWidth: 145, marginTop: '10%' }} onClick={() => openInNewTabApp('AppStore')} />
        </div>
    );
}
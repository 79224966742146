import React from 'react'
import { detectAnyAdblocker } from 'just-detect-adblock'


export default function en() {
  const openInNewTabApp = async (text) => {
    let url = window.generateUrl
    let detected = await detectAnyAdblocker()
    console.log(detected)
    console.log(window.browser)

    let body = {

      incomingURL: window.location.href,
      outcomingURL: url,
      browser: window.browser,
      adblock: detected,
      button: text
    }
    fetch('https://genesis-billing-server.herokuapp.com/log', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-type': 'application/json'
      }
    })
    window.fbq('track', 'Lead', { currency: "EUR", value: 0.00 });
    if(url === null){
      const newWindow = window.open(window.location.href ,'_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
    }else{
      const newWindow = window.open(url ,'_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
    }
  }
 
  return (
    <div className="App">
      <img src="/img/shorts/backs/7.png" style={{ width: '100%', position: 'absolute', zIndex: -1, marginTop: 0 }} />
      <header className="header1" style={{background:'none'}}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/img/logo.png" style={{ width: '54px', margin: 15,marginLeft:25 }} onClick={() => openInNewTabApp('Start now')}/>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img src="/img/shorts/tops/7.png" style={{ width: 200, margin: 15 }} onClick={() => openInNewTabApp('Start now')}/>
        </div>

      </header>
      <img src="/img/shorts/bottoms/7.png" style={{ width: '90%', marginTop: '165%' }} onClick={() => openInNewTabApp('Start now')}/>
      <img src="/img/app1.png" style={{ width: '60%', width: 205,height:64, marginTop: '5%' }} onClick={() => openInNewTabApp('AppStore')}/>
     
    </div>
  );
}
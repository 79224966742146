import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router'
import queryString from 'query-string';

const data = [
    {
        type: '_keyboard',
        text: 'Get Fonts',
        main: 'Best Fonts',
    }, {
        type: '_widget',
        text: 'Get Widgets',
        main: 'Best Widgets'
    },{
        type: '_wallpapers',
        text: 'Best Wallpapers',
        main: 'Get Wallpapers'
    }, {
        type: '_charging',
        text: 'Get Animation',
        main: ' Best Charging Animation'
    }, {
        type: '_sticker',
        text: 'Get Now',
        main: 'Change any message'
    }, {
        type: '_watch',
        text: 'Get Now',
        main: 'Best Apple Watch Keyboard'
    }, {
        type: '_keyboardkorean',
        text: '글꼴 가져 오기',
        main: '최고의 글꼴'
    }
    , {
        type: '_keyboardswedish',
        text: 'Skaffa teckensnitt',
        main: 'Bästa svenska teckensnitt'
    } , {
        type: '_keyboardjapanese',
        text: 'フォントを取得する',
        main: '最高の日本語フォント'
    }, {
        type: '_keyboardarabic',
        text: 'احصل على الخطوط',
        main: 'افضل الخطوط العربية'
    }
    , {
        type: '_avatarify',
        text: 'Install App',
        main: 'Revive any Photo'
    }
]

function Multilanding(props) {
    const [campaign, setCampaign] = useState(0)
    const [campaignName, setCampaignName] = useState('')
    const [image, setImage] = useState('')
    useEffect(() => {
        const parsed = queryString.parse(props.location.search);
        setImage(parsed.original_url_ad)
        data.forEach((elem,index)=>{
            console.log(elem)
            console.log(parsed.utm_campaign)
            if(parsed.utm_campaign.includes(elem.type)){
                setCampaign(index)
            }
        })
    }, [])

    const openInNewTab = () =>{

            window.gtag('event', 'conversion', {
                'send_to': 'AW-564572229/_GWDCJLJgPwBEMXgmo0C'
            })
        let url = window.generateUrl
        if(url === null){
            const newWindow = window.open(window.location.href ,'_blank', 'noopener,noreferrer')
          if (newWindow) newWindow.opener = null
          }else{
            const newWindow = window.open(url ,'_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
          }
    }

    return (
        <div className="App">
            <div className="multiheader">
                <img src={`/img/${(data[campaign].type === '_avatarify')?"photoeditor":"logo"}.png`} style={(data[campaign].type === '_avatarify')?{height: 56,border:'1px solid black',borderRadius:10}:{height: 56}}/>
                <button className="multiheader__button" onClick={openInNewTab}>{data[campaign].text}</button>
            </div>
            <p className="multimain-text">{data[campaign].main}</p>
            <img src={`/img/${image}${(image.includes('.png') || image.includes('.gif'))?"":".png"}`} style={{ width: '100%', marginBottom: -5 }} />
            <div className="multifooter">
                <button className="multifooter-button" onClick={openInNewTab}>{data[campaign].text}</button>
                <img src="/img/app1.png" style={{ width: '50%', marginTop: 32, marginBottom: 50 }} onClick={openInNewTab}/>
            </div>
        </div>
    )
}
export default withRouter(Multilanding);